<template>
  <div class="container py-5">
    <div class='embed-container'><iframe width='560' height='315' src='https://www.youtube-nocookie.com/embed/VGrUAfZPXEY?rel=0' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>
    <br>
    <p class="fw-bold">2023 臺灣醫用雷射光電學會</p>
    <p>展會時間：2023 03/25-26</p>
    <p>展會地點：華南銀行國際會議中心2樓</p>
    <p>攤位編號：20</p>
    <p>
      OBSERV即將參與“2023年臺灣醫用雷射光電學會”
      <br><br>
      OBSERV 520x的三大看點
      <br>
      💎膚色數值分析：清楚顯示面部的色素沈著、敏感泛紅處的紅棕分佈情形。<br>
      💎360度光源模式：光源角度變化，一眼看見臉部凹陷、肌膚紋理等問題。<br>
      💎App跨平台裝置：完整檢測報告可即時傳輸至多種裝置，諮詢場域不再受限。<br><br>
      現場更將會演示OBSERV全新AI功能技術『皮膚紅棕AI量測』<br>
      AI定位，數據判讀更加準確，協助您輕鬆追蹤色素、敏感泛紅的區域治療成效 <br><br>

      想體驗更多關於全臉影像分析檢測儀的資訊？<br>
      進一步了解OBSERV：<a href="http://observ.com.tw/product/520x">http://observ.com.tw/product/520x</a><br>

      案例報告：<a href="https://observ.com.tw/report/OBSERV520x_report1.html">https://observ.com.tw/report/OBSERV520x_report1.html</a><br>

      功能特色：<a href="https://observ.com.tw/features/OBSERV520x_features.html">https://observ.com.tw/features/OBSERV520x_features.html</a><br>
    </p>


  </div>
</template>
<script>
export default {};
</script>
